<template>
	<div ref="drop-base" class="drop-base">
		<div ref="base" @click.prevent.stop="handleToggle">
			<slot>
                <span class="rounded-md shadow-sm">
                    <button type="button"
							class="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-sm leading-5 font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800 transition ease-in-out duration-150">
                        {{ text }}
                        <i class="fa fa-caret-down ml-2 text-gray-400"></i>
                    </button>
                </span>
			</slot>
		</div>
		<transition
			enter-active-class="transition ease-out duration-100"
			enter-class="transform opacity-0 scale-95"
			enter-to-class="transform opacity-100 scale-100"
			leave-active-class="transition ease-in duration-75"
			leave-class="transform opacity-100 scale-100"
			leave-to-class="transform opacity-0 scale-95">
			<div v-if="state.show" ref="drop"
				 class="dropdown-content drop-container absolute origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-[91]"
				 :class="dropClass" :style="floatingStyles">
				<div class="py-1 flex flex-col">
					<slot name="items">
						<template v-for="(item, index) in items">
							<template v-if="item.icon">
								<a :key="`item-${index}`" @click="handleClick(item)"
								   class="group flex items-center px-4 py-2 text-sm cursor-pointer leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
								   role="menuitem"
								   :class="item.clss">
									<i class="mr-3" :class="[{'text-gray-400 group-hover:text-gray-500 group-focus:text-gray-500' : !item.iconColor },item.iconColor,item.icon]"></i>
									{{ item.text }}
								</a>
							</template>
							<template v-else-if="item === '--' || item.divider">
								<div :key="`item-${index}`" class="border-t" :class="[{'border-gray-100' : !item.color}, item.color]"></div>
							</template>
							<a v-else :key="`item-${index}`" @click="handleClick(item)"
							   class="block px-4 py-2 cursor-pointer text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900">
								{{ item }}
							</a>
						</template>
					</slot>
				</div>
			</div>
		</transition>
	</div>
</template>

<script>
import { computePosition, useFloating, flip, offset, autoPlacement, autoUpdate } from "@floating-ui/vue"

export default {
	name: "Dropdown",
	props: {
		items: Array,
		containerWidth: {
			type: String,
			default: "auto"
		},
		text: {
			type: String,
			default: "Selecione"
		},
		dropClass: String,
		placement: {
			type: String,
			default: "bottom-start"
		},
		alignment: String,
		virtual: Boolean,
		offset: Number
	},
	data() {
		return {
			floatingStyles: {
				left: 0,
				top: 0
			},
			state: {
				show: false
			}
		}
	},
	provide() {
		return {
			dropdownState: this.state
		}
	},
	methods: {
		handleToggle() {
			this.state.show = !this.state.show
		},
		handleClick(item) {
			this.state.show = false
			this.$emit("input", item)
		},
		handleClickAway(e) {
			const dropBase = e.target.closest(".drop-base")
			const dropContainer = e.target.closest(".drop-container")
			if (dropBase && dropBase === this.$refs.base) return
			if (dropContainer && dropContainer === this.$refs.drop) return

			this.state.show = false

			this.$emit("update:open", false)
		},
		handlePressEsc(e) {
			if (e.keyCode === 27) this.state.show = false
		}
	},
	watch: {
		"state.show"(value) {
			if (value) {
				document.addEventListener("mousedown", this.handleClickAway)
				document.addEventListener("keydown", this.handlePressEsc)

				const middleware = [autoPlacement({
					// top-start, right-start, bottom-start, left-start
					alignment: this.alignment,
					allowedPlacements: ["top", "bottom", "bottom-start", "bottom-end", "top-start", "top-end"]
				})]

				if (this.offset) middleware.push(offset(this.offset))

				if (this.virtual) {
					this.$nextTick(async () => {
						const { x, y } = await computePosition(this.$refs.base, this.$refs.drop, { middleware })

						Object.assign(this.floatingStyles, {
							left: `${x}px`,
							top: `${y}px`
						})
					})
				} else {
					this.$nextTick(async () => {
						const { x, y } = await computePosition(this.$refs.base, this.$refs.drop, { middleware })

						Object.assign(this.floatingStyles, {
							left: `${x}px`,
							top: `${y}px`
						})
					})
				}
			} else {
				document.removeEventListener("mousedown", this.handleClickAway)
				document.removeEventListener("keydown", this.handlePressEsc)
			}
		}
	}
}
</script>
<style scoped>
.dropdown-content {
	/* Float on top of the UI */
	position: absolute;

	/* Avoid layout interference */
	width: max-content;
	top: 0;
	left: 0;
}
</style>