<template>
	<div class="relative flex flex-col">
		<div class="table_wrap overflow-auto">
			<!-- Desktop !-->
			<table class="datatable min-w-full divide-y divide-gray-200 hidden sm:block">
				<!-- Header !-->
				<thead class="bg-gray-50" :class="headerClass">
				<tr>
					<template v-for="(col, index) in cols">
						<!-- Checkbox !-->
						<template v-if="index === '#'">
							<th class="pl-4 py-3 text-left text-xs leading-4 font-medium uppercase tracking-wider" :class="[thClass(col)]">
								<input type="checkbox" name="check-all" class="form-checkbox text-lg rounded" @click="handleCheckAll" v-model.lazy="checkAll"/>
							</th>
						</template>
						<!-- Actions !-->
						<template v-else-if="index === '@'">
							<th class="px-4 py-3 text-left text-xs leading-4 font-medium uppercase tracking-wider" :class="[thClass(col)]">
								<i class="fal fa-tools"></i>
							</th>
						</template>
						<!-- Default !-->
						<template v-else>
							<th class="relative px-4 py-3 text-left text-xs leading-4 uppercase font-semibold text-gray-900 tracking-wider whitespace-nowrap group"
								:class="[
									thClass(col),
									{
										'cursor-pointer select-none' : thOrder(col, index)
									}
								]"
								@click="handleOrder(col, index)">
								<span>{{ th(col) }}</span>
								<template v-if="thOrder(col, index)">
									<button type="button" class="ml-1 absolute opacity-50 hover:opacity-100 group-hover:opacity-100">
										<i class="fa" :class="thOrderDirection(col, index)"></i>
									</button>
								</template>
							</th>
						</template>
					</template>
				</tr>
				</thead>
				<!-- Content !-->
				<tbody class="divide-y divide-gray-200">
				<template v-for="(row, rowIndex) in data">
					<template v-if="isDetachedRow(row, rowIndex)">
						<tr :key="`${rowIndex}-${row.$key}-detached`" class="group" :class="rowClass">
							<td :colspan="totalCols" :class="['p-0',detachedClass()]">
								<slot name="detached" :data="{ row, rowIndex, value: extra(row, rowIndex), onClose: () => closeDetached(row, rowIndex) }"/>
							</td>
						</tr>
					</template>
					<template v-else>
						<tr :key="`${rowIndex}-${row.$key}`" class="group cursor-pointer"
							:class="[rowClass, { [rowCheckedClass]: row.checked, ['text-red-600 animate-pulse'] : row.is_deleting, }]"
							@click="handleClickRow($event, row, rowIndex)"
							@contextmenu="handleContextMenu($event, rowIndex)"
							@mouseover="handleMouseOver($event, rowIndex)">
							<template v-for="(col, colIndex) in cols">
								<!-- Checkbox !-->
								<template v-if="colIndex === '#'">
									<td
										:key="`col-${colIndex}`"
										class="relative max-w-0 pl-4 py-4 text-sm leading-5"
										:class="[tdClass(col), tdCheckedClass(row.checked, colIndex)]"
										@click.stop="() => {}">
										<template v-if="row.checked">
											<div class="absolute inset-0 w-1 bg-turquoise"></div>
										</template>
										<input :key="`check-${colIndex}`" type="checkbox" name="ids[]" :checked="row.checked" class="form-checkbox rounded text-lg"
											   @click="handleSelectRow(row, rowIndex)"/>
									</td>
								</template>
								<!-- Actions !-->
								<template v-else-if="colIndex === '@'">
									<td
										:key="`col-${colIndex}`"
										class="max-w-0 px-4 py-4 whitespace-nowrap text-sm leading-5"
										:class="[tdClass(col), tdCheckedClass(row.checked, colIndex)]"
										@click.stop="() => {}">
										<slot :name="`col-${colIndex}`" :data="{ row, col, colIndex, rowIndex, value: getValue(col, colIndex, row, rowIndex) }">
											{{ td(col, colIndex, row, rowIndex) }}
										</slot>
									</td>
								</template>
								<!-- Default !-->
								<template v-else>
									<td
										:key="`col-${colIndex}`"
										class="p-4 text-sm leading-5"
										:class="[tdClass(col), tdCheckedClass(row.checked, colIndex)]"
										@mousedown.prevent="handleMouseDownTd($event, row, rowIndex)"
										@click="$emit('td-click', col, colIndex, row)">
										<slot :name="`col-${colIndex}`" :data="{ row, col, colIndex, rowIndex, value: getValue(col, colIndex, row, rowIndex) }">
											{{ td(col, colIndex, row, rowIndex) }}
										</slot>
									</td>
								</template>
							</template>
						</tr>
						<template v-if="hasExtraLine(row, rowIndex)">
							<tr :key="`${rowIndex}-${row.$key}-extra_line`" class="group">
								<td :colspan="totalCols" class="p-0">
									<slot name="extraLine" :data="{ row, rowIndex, value: extra(row, rowIndex), onClose: () => closeExtraLine(row, rowIndex) }">
										{{ extra(row, rowIndex) }}
									</slot>
								</td>
							</tr>
						</template>
					</template>
				</template>
				</tbody>
			</table>
		</div>
	</div>
</template>

<script>

import _ from "lodash"
import dayjs from "dayjs"

export default {
	name: "AutoTable2",
	props: {
		header: [String, Array],
		headerClass: String,
		row: [Object, String], // { prop-name: Header }
		cols: [Object, Array], // { prop-name: Header }
		value: Array, // { prop-name: Header },
		clickSelect: Boolean,
		handlers: Function
	},
	data() {
		return {
			checkAll: false,
			data: {},
			autoupdate: [],
			updater: null,
			current_order: null,
			mapIndex: {},
			totalCols: 0
		}
	},
	computed: {
		ths() {
			if (this.header === '*') return Object.keys(this.data[0])

			return this.header
		},
		rowClass() {
			if (typeof this.row == 'string') return this.row

			return this.row['class'] ?? ''
		},
		rowCheckedClass() {
			if (typeof this.row == 'string') return this.row

			return this.row['checkedClass'] ?? ''
		}
	},
	methods: {
		th(col) {
			if (!col) return '';

			if (typeof col == 'string') return col

			return col.th
		},
		thClass(col) {
			if (!col) return '';

			if (typeof col == 'string') return null

			// return col.thClass ?? null

			return [
				this.row['thClass'],
				col?.thClass, col?.class
			]
		},
		tdClass(col) {
			if (typeof col == 'string') return null

			return [
				this.row['tdClass'] ? this.row['tdClass'] : '',
				col?.tdClass, col?.class
			]
		},
		tdCheckedClass(checked, index) {
			// if (typeof this.row == 'string') return this.row

			if (!checked) return ''

			if (!this.row['tdCheckedClass']) return null

			const classIndex = this.mapIndex[index] === 0 ? 'first' : this.mapIndex[index] < this.totalCols - 1 ? 'middle' : 'last'

			if (this.mapIndex[index] > 0 && typeof this.row['tdCheckedClass'] == 'string') return null

			if (typeof this.row['tdCheckedClass'] === 'object') {
				return this.row['tdCheckedClass'][classIndex]
			}

			return this.row['tdCheckedClass']
		},
		detachedClass() {
			return this.row['detachedClass'] ?? ''
		},
		getValue(col, colIndex, row, rowIndex) {
			if (!col) return '';

			return this.td(col, colIndex, row, rowIndex)
		},
		td(col, colIndex, row, rowIndex) {

			if (!col) return '';

			let colName = col;
			let colDefault = ''
			let colHandler = (val) => val

			if (typeof col != 'string') {
				colName = colIndex
				if (col.handler) colHandler = col.handler
				if (col.default) colDefault = col.default
			}

			const rawValue = _.get(row, colName, colDefault)

			// FIX
			if (col.formater) col.formatter = col.formater

			let val = col.formatter ? this.formatters(col.formatter, rawValue, rowIndex) : rawValue

			if (col?.localized) val = this.$localize(val)

			return colHandler(val, colName, row)
		},
		extra(row, rowIndex) {

			if (!row) return null;

			return _.get(row, 'extra_line', null)
		},
		formatters(formatter, value, rowIndex) {

			const [type, ...format] = formatter.split(':')

			const formatters = {
				'date': this.formatDate
			}

			return formatters[type] ? formatters[type](format.join(':'), value, rowIndex) : value

		},
		formatDate(format, value, rowIndex) {
			if (!value) return value

			let date = dayjs(value)

			if (format == 'fromNow') {

				if (!date.diff(dayjs(), 'h')) {
					this.pushUpdateble(rowIndex)
					this.data[rowIndex].$observer = date
				}

				return date.fromNow()
			}

			return date.format(format)
		},
		pushUpdateble(index) {
			if (this.autoupdate.indexOf(index) === -1) this.autoupdate.push(index)
		},
		handleSelectRow(row, index) {
			this.data[index].checked = !this.data[index].checked

			this.checkAll = !this.data.some(row => !row.checked)

			this.$emit('input', this.data)
			this.$emit('checked', row, index)
			this.$emit('row-checked', {autoTable: this, row, index, checked: row.checked})

		},
		handleCheckAll() {
			this.data.forEach(row => row.checked = !this.checkAll)

			this.$emit('input', this.data)
			this.$emit('row-checked', {autoTable: this})
			this.$emit('check-all')
		},
		handleClickRow(evt, row, rowIndex) {

			if (this.clickSelect || evt.shiftKey) {
				return this.handleSelectRow(row, rowIndex)
			}

			this.$emit('row-click', evt, row, rowIndex)
		},
		handleContextMenu(evt, rowIndex) {
			this.$emit('row-context', {evt, rowIndex})
		},
		handleMouseOver(evt, rowIndex) {
			this.$emit('row-mouseover', {evt, rowIndex})
		},
		_autoupdate() {
			if (!this.autoupdate.length) return;

			if (this.updater) clearTimeout(this.updater)

			this.updater = setTimeout(() => {
				if (this.autoupdate.length) {

					this.autoupdate.forEach((index) => {
						this.data[index].$key += (new Date).getMilliseconds()
					})

					this.autoupdate
					.filter((index) => this.data[index].$observer.diff(dayjs(), 'h'))
					.forEach((index) => this.autoupdate.splice(index, 1))

					this._autoupdate()
				}
			}, 60000)
		},
		setup() {
			this.checkAll = false
			this.autoupdate = []
			this.data = this.value
			this.data.forEach(row => {
				this.$set(row, 'checked', !!row.checked)
				this.$set(row, '$key', (new Date).getMilliseconds())
				this.$set(row, '$observer', null)
			})
			Object.keys(this.cols).forEach((col, index) => {
				this.mapIndex[col] = index
			})

			this.totalCols = Object.keys(this.cols).length

			this.makeOrder()

			this.$nextTick(this._autoupdate)
		},
		makeOrder() {
			if (this.current_order) return;

			this.current_order = {}

			Object.keys(this.cols).forEach((col) => {
				let item = this.cols[col]?.sort

				if (item === undefined) return;

				if (item === true) {
					this.$set(this.current_order, col, null)
					return;
				}

				this.$set(this.current_order, item, item === 'id' ? 'asc' : null)
			})
		},
		checked() {
			return this.data.filter(row => row.checked)
		},
		checkedIds() {
			return this.data.filter(row => row.checked).map(row => row.id)
		},
		hasCheckedRows() {
			return this.data.find(row => row.checked)
		},
		checkedRows() {
			return this.data.filter(row => row.checked)
		},
		clearCheckedRows() {
			this.checkAll = false
			this.data.forEach(row => row.checked = false)
			this.$emit('row-checked', {autoTable: this})
		},
		thOrder(col, colId) {
			const id = typeof col.sort === 'string' ? col.sort : colId ?? colId
			return this.current_order[id] !== undefined
		},
		thOrderDirection(col, colId) {
			const id = typeof col.sort === 'string' ? col.sort : colId ?? colId
			const direction = this.current_order[id]
			if (direction === null) return ''

			return direction === 'asc' ? 'fa-arrow-up-wide-short' : 'fa-arrow-down-short-wide'
		},
		handleOrder(col, colId) {
			const id = typeof col.sort === 'string' ? col.sort : colId ?? colId

			if (this.current_order[id] === undefined) return;

			if (this.current_order[id] === 'desc' && id !== 'id') {
				this.$set(this.current_order, id, null)
			} else if (this.current_order[id] === 'asc') {
				this.$set(this.current_order, id, "desc")
			} else {
				this.$set(this.current_order, id, "asc")
			}

			if (id !== 'id') {
				this.$set(this.current_order, 'id', null)
			} else {
				Object.keys(copy_obj(this.current_order)).forEach((item) => item !== 'id' ? this.$set(this.current_order, item, null) : null)
			}

			this.emitSort()
		},
		clearSort() {
			this.current_order = null
			this.makeOrder()
			this.emitSort()
		},
		emitSort() {
			const order = copy_obj(this.current_order)
			const sort = []
			Object.keys(order).forEach((key) => {
				if (order[key] === null) return;
				let direction = order[key] === 'desc' ? '' : '-'
				sort.push(direction + key);
			})

			this.$emit('sort', {sort: sort.join(',')})
		},
		hasExtraLine(row, rowIndex) {
			return row.extra_line !== null && row.extra_line !== undefined
		},
		isDetachedRow(row, rowIndex) {
			return row.detached
		},
		closeDetached(row, rowIndex) {
			this.$set(row, 'detached', false)
			this.$set(this.data, rowIndex, row)
			this.$emit('input', this.data)
		},
		closeExtraLine(row, rowIndex) {
			this.$set(row, 'extra_line', null)
			this.$set(this.data, rowIndex, row)
			this.$emit('input', this.data)
		},
		handleMouseDownTd(evt, row, rowIndex) {

			if (evt.ctrlKey || evt.metaKey) {
				evt.preventDefault()
			}
		}
	},
	created() {
		this.setup()
	},
	destroyed() {
		if (this.updater) clearInterval(this.updater)
	},
	watch: {
		value() {
			this.setup()
		}
	}
}
</script>