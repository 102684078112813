<template>
	<div ref="target_input" class="target_input relative w-full">
		<FormInput ref="input"
				   :value="innerText"
				   class="w-full "
				   :validation="validation"
				   :error="error"
				   @keydown="handleNativation"
				   @focus="handleInputFocus"
				   @blur="handleInputBlur"
				   @input="handleInput"
				   right-icon="fal fa-search text-gray-400"
		/>
		<!-- Search List !-->
		<template v-if="canShowResult">
			<div ref="drop" class="mt-2 bg-white py-2 min-w-full w-fit absolute left-0 z-10 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:ring-blue-500" :class="dropClass">
				<!-- List !-->
				<template v-if="!isSearchLoading">
					<!-- Search result !-->
					<template v-if="hasItems">
						<!-- List !-->
						<ul class="max-h-32 lg:max-h-64 text-base overflow-auto focus:outline-none sm:text-sm divide-y divide-gray-200"
							@mouseover="currentSearchIndex = null"
							tabindex="-1"
							role="listbox">
							<template v-for="(item, index) in searchList">
								<!-- Item !-->
								<li :key="`search-${index}`"
									:ref="`search-${index}`"
									role="option"
									tabindex="0"
									class="cursor-pointer text-gray-900 select-none relative py-2.5 pl-3 pr-9 hover:bg-turquoise hover:text-white focus:outline-none focus:bg-turquoise focus:text-white hover:font-semibold focus:font-semibold group"
									:class="{ 'bg-turquoise text-white font-semibold': currentSearchIndex === index }"
									@mousedown="handleSelectItem(item, index)"
									@keydown.enter="handleSelectItem(item, index)">
									<slot name="item" :data="{ item, localized: localized(item) }">
										<!-- Label !-->
										<span class="whitespace-nowrap">{{ localized(item) }}</span>
									</slot>
								</li>
							</template>
						</ul>
						<!-- / List !-->
					</template>
					<template v-else-if="innerText">
						<p class="p-3 text-center text-xs">Nenhum resultado para: <span>"{{ innerText }}"</span></p>
					</template>
				</template>
				<!-- / List !-->
				<!-- Loading !-->
				<div v-else class="relative py-6">
					<Loading use-spinner />
				</div>
				<!-- / Loading !-->
			</div>
		</template>
	</div>
</template>

<script>
import SupportTickets from "@/API/backoffice/SupportTickets.js"
import FormInput from "@/components/FormInput.vue"
import Loading from "@/components/Loading.vue"
import ErrorBag from "@/components/ErrorBag.vue"

export default {
	name: "FormSearchableInput2",
	components: { ErrorBag, Loading, FormInput },
	model: {
		prop: "selected",
		event: "update:selected"
	},
	props: {
		placeholder: String,
		text: [String, Number],
		selected: [Object, String, Number],
		dropClass: String,
		searchEndpoint: String,
		searchParams: Array,
		allowUnselected: Boolean,
		asText: Boolean,
		search: Function,
		searchInterval: {
			type: Number,
			default: 500
		},
		minSearchLength: {
			type: Number,
			default: 0
		},
		validation: Object,
		error: Object
	},
	data() {
		return {
			searchEventThrottle: null,
			innerText: "",
			eventBind: false,
			showResult: false,
			showOptionsResult: {},
			isLoadingOptions: {},
			isSearchLoading: false,
			optionList: {},
			searchList: [],
			currentTagIndex: null,
			currentSearchIndex: null
		}
	},
	computed: {
		/**
		 * Calculate if has items
		 * @returns {boolean}
		 */
		hasItems() {
			return this.searchList?.length > 0
		},
		/**
		 * Calculate if can show result
		 * @returns {boolean}
		 */
		canShowResult() {
			return this.showResult && this.innerText
		},
		hasError() {
			return this.validation?.$error
		}
	},
	methods: {
		/**
		 * Handle input
		 * @param evt
		 */
		async handleInput(evt) {
			if (!this.asText) this.innerText = evt
			this.$emit("update:text", evt)

			if (this.searchEventThrottle) clearTimeout(this.searchEventThrottle)

			if (this.innerText === "") {
				// this.$emit('update:selected', null)
				this.$emit("update:selected", { id: null, label: null })
				this.searchList = []
				this.showResult = false

				return false
			}

			if (this.minSearchLength < this.innerText?.length) {

				this.isSearchLoading = true

				this.searchEventThrottle = setTimeout(async () => {
					this.searchList = await this._search(this.innerText)
					this.searchEventThrottle = null

					if (this.searchList?.length > 0) {
						this.currentSearchIndex = 0
					}
				}, this.searchInterval)

				this.showResult = true

			}
		},
		/**
		 * Handle input blut
		 */
		handleInputBlur() {
			if (this.innerText === "" || !this.allowUnselected && this.selected && !this.selected.id) {
				this.emitInput(null)
				return false
			}

			if (!this.asText && this.selected) {
				const label = this.selected.label ?? this.selected.title_locale ?? this.selected.title
				if (typeof label === "object") {
					this.innerText = this.$localize(label)
				} else {
					this.innerText = label
				}
			} else if (!this.selected && !this.allowUnselected) {
				this.emitInput(null)
			}

			this.showResult = false
		},
		/**
		 * Handle select item
		 */
		handleSelectItem(item, index) {
			if (!item) return false

			this.emitInput(item)

			this.showResult = false
			this.$nextTick(() => setTimeout(() => this.$refs.input.focus()))

			return true
		},
		/**
		 * Action clear
		 */
		clear() {
			this.emitInput(null)
		},
		/**
		 * Action focus
		 */
		focus() {
			this.$refs.input.focus()
		},
		/**
		 * Handle input focus
		 */
		handleInputFocus() {
			this.currentTagIndex = null
			this.bindDocumentEvents()
		},
		/**
		 * Handle nativation input
		 * @param evt
		 */
		handleNativation(evt) {

			// Limit listen events
			const actionKeys = [
				"ArrowUp",
				"ArrowDown",
				"Backspace",
				"Delete",
				"Escape",
				"Enter"
			]

			if (!actionKeys.includes(evt.key)) return

			// Clear positions
			if (evt.key === "Escape") {

				if (this.asText) {
					this.currentSearchIndex = null
					this.showResult = false
					return
				}

				this.innerText = null
				this.emitInput(null)
				this.currentSearchIndex = null
				this.showResult = false
				this.$refs.input.focus()
				return
			}

			// Search
			this.searchNavigation(evt)
		},
		/**
		 * Handle search navigation
		 * @param evt
		 */
		searchNavigation(evt) {
			if (evt.key === "Enter" && this.currentSearchIndex != null && this.currentSearchIndex >= 0) {
				this.handleSelectItem(this.searchList[this.currentSearchIndex], this.currentSearchIndex)
				return
			}

			if (evt.key === "ArrowUp" || evt.key === "ArrowDown") {

				if (this.currentSearchIndex === null) {
					this.currentSearchIndex = 0
					return
				}

				if (evt.key === "ArrowUp") {
					this.currentSearchIndex--
				}

				if (evt.key === "ArrowDown") {
					this.currentSearchIndex++
				}

				// Limit start index
				if (this.currentSearchIndex < 0) this.currentSearchIndex = 0

				// Limit last index
				if (this.currentSearchIndex > this.searchList.length - 1) this.currentSearchIndex = this.searchList.length - 1
			}
		},
		/**
		 * Action search
		 * @returns {Promise<Array>}
		 */
		async _search(search) {
			this.isSearchLoading = true

			let list

			if (this.search) {
				list = await this.search(search)
				this.isSearchLoading = false
				return list
			}

			const [err, res] = await SupportTickets.searchAddress({ search })
			this.isSearchLoading = false

			if (err) {
				// TODO: Handle error
				return []
			}

			return res.data?.data
		},
		/**
		 * Format label for any item
		 * @param item
		 * @returns {string}
		 */
		localized(item) {
			const label = []

			if (item.code) {
				label.push(`${item.code} - `)
			}

			if (typeof item.label === "string") {
				label.push(item.label)
			} else {
				label.push(this.$localize(item.label))
			}

			return label.join("")
		},
		/**
		 * Calculate search item icon
		 * @param selected_type
		 * @returns {*}
		 */
		searchTypeIcon(selected_type) {
			return {
				"user": "fa-user",
				"company": "fa-building",
				"department": "fa-layer-group"
			}[selected_type]
		},
		/**
		 * Calculate search tag icon
		 * @param selected_type
		 * @returns {*}
		 */
		tagClass(selected_type) {
			return {
				"user": "bg-blue-100 text-gray-800",
				"company": "bg-gray-100 text-gray-800",
				"department": "bg-yellow-100 text-gray-800"
			}[selected_type]
		},
		emitInput(selected) {
			if (selected === null) {
				this.innerText = null
				this.$emit("update:text", null)
				this.$emit("update:selected", { id: null, label: null })
				return false
			}
			this.$emit("update:selected", selected)

		},
		/**
		 * Handle click outside
		 * @param evt
		 */
		awayEvent(evt) {
			const closest = evt.target.closest(".target_input")
			if (closest && closest === this.$refs.target_input) {
				return false
			}
			this.showResult = false
			this.showOptionsResult = []
			this.unbindDocumentEvents()
		},
		/**
		 * Bind document events
		 */
		bindDocumentEvents() {
			if (this.eventBind) return
			this.eventBind = true
			document.addEventListener("keydown", this.handleNativation)
			document.addEventListener("click", this.awayEvent)
		},
		/**
		 * Unbind document events
		 */
		unbindDocumentEvents() {
			this.eventBind = false
			document.removeEventListener("keydown", this.handleNativation)
			document.removeEventListener("keydown", this.awayEvent)
		}
	},
	watch: {
		selected: {
			handler(val) {
				if (val === undefined) return
				if (val === null || val === "" || !val?.id && !this.asText) {
					this.innerText = ""
				} else {
					const label = val.label ?? val.title_locale ?? val.title
					if (typeof label === "object") {
						this.innerText = this.$localize(label)
					} else {
						this.innerText = label
					}

				}
			},
			immediate: true
		},
		text: {
			handler(val) {
				if (val === undefined) return
				this.innerText = val
			},
			immediate: true
		}
	}
}
</script>