<template>
	<div>
		<!-- Title !-->
		<h3 class="text-lg leading-6 font-medium text-gray-900 pl-4 sm:pl-0">
			Totais
		</h3>
<!--		<div class="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">-->
		<div class="mt-5">
			<!-- LastTwelveMonthsChart -->
			<LastTwelveMonthsChart />
<!--			<LastTwelveMonthsChart class="col-span-2" />-->
			<!-- CurrentMonthsSupportTicketChart -->
<!--			<CurrentMonthsSupportTicketChart />-->
		</div>
	</div>
</template>
<script>
import Dashboard from "@/API/Dashboard.js"
import Chart from 'chart.js/auto'

import Card from "@/components/Card.vue"
import LastTwelveMonthsChart from "./LastTwelveMonthsChart.vue"
import CurrentMonthsSupportTicketChart from "./CurrentMonthsSupportTicketChart.vue"
export default {
	name: "ChartsPartial",
	components: {
		CurrentMonthsSupportTicketChart,
		LastTwelveMonthsChart,
		Card
	}
}
</script>