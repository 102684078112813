<template>
	<div>
		<!-- input wrap !-->
		<div class="rounded-md shadow-sm relative">
			<!-- Left icon !-->
			<div v-if="leftIcon" class="pl-3 left-0 flex inset-y-0 absolute items-center pointer-events-none">
				<i :class="leftIcon"></i>
			</div>
			<!-- Input !-->
			<input
				ref="input"
				class="block w-full min-w-0 rounded-md sm:text-sm"
				:class="computedClass"
				:type="type"
				:value="value"
				:placeholder="placeholder"
				:disabled="disabled || readOnly"
				@input="$emit('input', $event.target.value)"
				@keypress.enter="$emit('enter', $event.target.value)"
				@blur="$emit('blur', $event.target.value)"
				@keydown="$emit('keydown', $event)"
			/>
			<!-- Right Icon !-->
			<div v-if="rightIcon" class="pr-3 right-0 inset-y-0 flex absolute items-center pointer-events-none">
				<i :class="rightIcon"></i>
			</div>
			<!-- Validation Icon !-->
			<div v-if="hasError" class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
				<i class="fa fa-exclamation-circle text-red-500 opacity-75"></i>
			</div>
			<!-- Loading !-->
			<Loading v-if="loading" use-spinner/>
		</div>
		<!-- /input wrap !-->
		<!-- ErrorBag !-->
		<ErrorBag v-if="hasError" :placeholder="placeholder" :validation="validation" v-bind="error"/>
		<!-- Info !-->
		<div v-if="$slots.info" class="mt-2 text-sm text-gray-500 flex items-center">
			<slot name="info"/>
		</div>
	</div>
</template>

<script>
import Loading from "@/components/Loading"
import ErrorBag from "@/components/ErrorBag"

export default {
	name: "FormInput",
	components: {Loading, ErrorBag},
	props: {
		value: [String, Number],
		inputClass: String,
		placeholder: String,
		validation: Object,
		serverError: Object,
		hideValidationMessage: Boolean,
		leftIcon: String,
		rightIcon: String,
		error: Object,
		loading: Boolean,
		disabled: Boolean,
		readOnly: Boolean,
		inputDefault: {
			type: String,
			default: 'form-input'
		},
		defaultInputClass: {
			type: String,
			default: 'form-input'
		},
		errorClass: {
			type: String,
			default: 'error'
		},
		type: {
			type: String,
			default: 'text'
		}
	},
	computed: {
		computedClass() {
			return [
				this.defaultInputClass,
				{
					[this.errorClass]: this.hasError
				}
			]
		},
		hasError() {
			return this.validation?.$error
		}
	},
	methods: {
		focus() {
			this.$refs.input.focus()
		}
	}
}
</script>
