<i18n src="@/i18n/backoffice/dashboard/page.json"></i18n>
<template>
	<Page
		:title="$t('dashboard.title')"
		icon="fa fa-home text-turquoise">

		<div class="mt-2 flex flex-col space-y-6">
			<TotalsPartial />
			<ChartsPartial />
		</div>
	</Page>
</template>

<script>
import Mixin from "./mixins/dashboard.js"

import Page from "@/components/Page.vue"

import TotalsPartial from "./partials/TotalsPartial.vue"
import ShortcutsPartial from "./partials/ShortcutsPartial.vue"
import ChartsPartial from "./partials/ChartsPartial.vue"

export default {
	name: "DashboardPage",
	mixins: [Mixin],
	components: {
		ChartsPartial,
		Page,
		TotalsPartial,
		ShortcutsPartial
	},
	provide() {
		return {
			dashboard: this.page
		}
	},
	methods: {
		handleLoadBackground() {
			this.load(true)
		}
	},
	created() {
		this.init()
	}
}
</script>
