<template>
	<a @click.prevent.stop="handleClick"
	   :class="$tw('inline-flex justify-between space-x-2 cursor-pointer items-center px-4 py-2 text-sm leading-5 text-gray-700 group hover:bg-gray-100 hover:text-gray-900 focus:bg-gray-100 focus:text-gray-900 focus:outline-none', buttonClass)"
	   role="menuitem">
		<template v-if="leftIcon">
			<i class="mr-auto" :class="$tw('text-gray-400 group-hover:text-turquoise group-focus:text-gray-500', leftIcon)"></i>
		</template>
		<slot><span>{{ text }}</span></slot>
		<template v-if="icon || rightIcon">
			<i class="ml-auto" :class="$tw('text-gray-400 group-hover:text-turquoise group-focus:text-gray-500', icon, rightIcon)"></i>
		</template>
	</a>
</template>

<script>
export default {
	name: "DropdownItem",
	props: {
		text: String,
		icon: String,
		leftIcon: String,
		rightIcon: String,
		value: [String, Number, Object],
		buttonClass: String
	},
	inject: ["dropdownState"],
	methods: {
		/**
		 * Handle click
		 */
		handleClick() {
			this.dropdownState.show = false
			this.$emit("click", this.value)
		}
	}
}
</script>
